import React from 'react';
import PropTypes from 'prop-types';
import { SlashContainer } from '@zolteam/axenergie-ui-library';
import { useMeasure } from 'react-use';
// Constants
import colors from '../../constants/Colors';
// Hooks
import useIsMobile from '../../hooks/useIsMobile';

const HeaderWithSlashContainer = ({
  children, imageUrl, backgroundColor, rightComponent, rightColor, news,
}) => {
  const [leftBlockRef, { height }] = useMeasure();
  const [rightChildRef, { height: rightChildHeight }] = useMeasure();
  const [isMobile] = useIsMobile();

  const renderMobile = () => (
    <div className="full-width d-flex f-column">
      <div
        className="full-width background-cover d-flex justify-center"
        style={{
          minHeight: imageUrl ? 200 : 16,
          marginBottom: '-40px',
          background: imageUrl
            ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), center url(${imageUrl})` : rightColor,
        }}
      >
        <div
          className="mv-6"
          style={{
            marginTop: imageUrl ? 100 : 16,
          }}
        >
          {rightComponent}
        </div>
      </div>
      <SlashContainer
        width="100%"
        backgroundColor={backgroundColor}
        offset={20}
        position="top"
      >
        <div className="d-flex f-column justify-start align-start">
          {children}
        </div>
      </SlashContainer>
    </div>
  );

  const renderDesktop = () => (
    <>
      {/* left container */}
      <SlashContainer width="50%" backgroundColor={backgroundColor} offset={20}>
        <div
          ref={leftBlockRef}
          className="full-size d-flex f-column justify-center align-center"
          style={{
            minHeight: rightChildHeight + 200,
          }}
        >
          <div className="full-size pl-6 pv-3">
            {children}
          </div>
        </div>
      </SlashContainer>
      {/* Right container */}
      <div
        className="d-flex justify-start align-center"
        style={{
          width: '65%',
          background: imageUrl
            ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), center url(${imageUrl})` : rightColor,
          height,
          backgroundSize: news ? 'contain' : 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div ref={rightChildRef} className="full-width">
          {rightComponent}
        </div>
      </div>
    </>
  );

  return isMobile ? renderMobile() : renderDesktop();
};

HeaderWithSlashContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  imageUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
  rightColor: PropTypes.string,
  rightComponent: PropTypes.node,
  news: PropTypes.bool,
};

HeaderWithSlashContainer.defaultProps = {
  backgroundColor: colors.white,
  rightComponent: null,
  imageUrl: null,
  rightColor: colors.grey800,
  news: false,
};

export default HeaderWithSlashContainer;
